import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDataProvider, showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';

const IpgSessionModalContent = (props) => {
  const [picture, setPicture] = useState(`data:image/png;base64, ${props.sessionData.picture}`);

  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  const imageUploadID = "ipgimage-upload";

  const onUploadPicture = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      dataProvider.update('IpgSessions', {
        id: props.sessionData.sessionId,
        data: {
          ...props.sessionData,
          formPicture: reader.result.substring(reader.result.indexOf(',') + 1, reader.result.length),
          endTime: new Date().toISOString(),
          picture: null // We don't have to send existing picture if there is one
        }
      })
        .then(() => setPicture(reader.result))
        .catch(error => {
          dispatch(
            showNotification('Failed to upload the picture', 'error', {
              autoHideDuration: 5000
            }));
        });
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }

    // onChange event doesn't fire again if we upload the same file
    // Reset file upload input value so we can reupload the same file if needed
    let input = document.getElementById(imageUploadID);
    input.value = "";
  }

  return (
    <div>
      <img
        src={picture}
        alt="No picture found"
        style={{ marginBottom: "10px", width: "550px" }}
      />
      <br />
      <Button
        variant="contained"
        component="label"
      >
        <input id={imageUploadID} type="file" onChange={onUploadPicture} hidden />
        Upload file
      </Button>

    </div>
  );
}

export default IpgSessionModalContent;