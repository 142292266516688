// Authenticator by default
import jwt_decode from 'jwt-decode';
const loginPage = (process.env.REACT_APP_API_URL || window.location.origin) + '/api/identity/Login';

export default {
    login: ({ username, password }) => {
        const request = new Request(loginPage, {
            method: 'POST',
            // credentials: 'include',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                Username: username,
                Password: password,
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.text();
            })
            .then((token) => {
                localStorage.setItem('token', token);
                const decoded = jwt_decode(token);
                localStorage.setItem('roles', decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

    getPermissions: () => {
        const roles = localStorage.getItem('roles');
        return roles ? Promise.resolve(roles) : Promise.reject();
    },

    getPermissionsByResource: ( resource ) => {
        const roles = localStorage.getItem('roles').toLowerCase();
        const admin = roles.includes("administrator");
        const localadmin = roles.includes("localadmin");
        const specialist = roles.includes("specialist");
        const patient = roles.includes("patient");

        if (resource === "patients") {
            return {
                read: admin || localadmin || specialist,
                create: admin || localadmin || specialist,
                update: admin || localadmin || specialist,
                delete: false //TODO: figure out deletion/deactivation of users
            }
        }

        if (resource === "specialists") {
            return {
                read: admin || localadmin || patient,
                create: admin || localadmin,
                update: admin || localadmin,
                delete: false //TODO: figure out deletion/deactivation of users
            }
        }

        if (resource === "departments") {
            return {
                read: admin,
                create: admin,
                update: admin,
                delete: admin
            }
        }

        if (resource === "modules") {
            return {
                read: admin || localadmin || specialist,
                create: admin,
                update: admin,
                delete: admin
            }
        }

        if (resource === "relocationrequests") {
            return {
                read: admin || localadmin,
                create: admin || localadmin,
                update: false,
                delete: admin || localadmin
            }
        }

         if (resource === "devices") {
             return {
                 read: admin || localadmin || specialist,
                 create: admin || localadmin,
                 update: admin || localadmin,
                 delete: admin || localadmin
             }
         }

        if (resource === "sessions") {
            return {
                read: admin || specialist || patient,
                create: false,
                update: admin || specialist,
                delete: admin || specialist || patient,
            }
        }

        if (resource === "disabilities") {
            return {
                read: admin || localadmin || specialist,
                create: admin,
                update: admin,
                delete: admin
            }
        }
    }
};
