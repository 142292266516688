import React, { useRef } from 'react';
import { useDataProvider, Title } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable from '../enhancedTable/EnhancedTable';
import SessionModal from './SessionModal';
import authProvider from '../authProvider';
import createEditableRowOps from '../editableRowOps';
import createDataExtension from '../dataExtension';

const SessionList = props => {
    const endpoint = 'sessions';

    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    const tableRef = useRef();
    const permissions = authProvider.getPermissionsByResource(endpoint);
    const EditableRowOps = createEditableRowOps(dataProvider, dispatch);

    const dataExtension = createDataExtension(dataProvider, dispatch, 'modules', 'moduleId', 'name');
    const modulesLookupTable = useSelector(state => state.lookupReducer.modules);
    dataExtension.useLookupNames(modulesLookupTable);

    const toggleDetailPanel = (rowId) => tableRef.current.onToggleDetailPanel(
        [rowId],
        tableRef.current.props.detailPanel
    )

    return (
        <div className="ListLayoutFullWidth">
            <Title title='resources.sessions.name' />
            <EnhancedTable
                {...{
                    title: 'resources.sessions.name',
                    tableName: endpoint,
                    outerRef: tableRef,
                    columns: [
                        { field: 'sessionId', hidden: true },
                        { field: 'sessionShortId', type: 'numeric' },
                        { field: 'patientUsername', editable: 'never' },
                        { field: 'score', type: 'numeric' },
                        { field: 'startTime', type: 'date', customTitle: 'startDate', dateSetting: { locale: 'lt' }, defaultSort: 'desc' },
                        { field: 'startTime', type: 'time', dateSetting: { locale: 'lt' }, filtering: false },
                        { field: 'endTime', type: 'datetime', dateSetting: { locale: 'lt' } },
                        { field: 'notes' },
                        { field: 'moduleId', lookupTable: 'modules', editable: 'never' }
                    ],
                    detailPanel: (rowData) => 
                        <SessionModal 
                            sessionId={rowData.sessionId} 
                            togglePanel={function () { toggleDetailPanel(rowData.tableData.id) }} 
                        />,
                    onRowClick: (event, rowData, togglePanel) => togglePanel(),
                    customOptions: {
                        exportButton: true,
                        exportAllData: true
                    },
                    editable: EditableRowOps([endpoint, 'sessionId', permissions])
                }}
            />
        </div>
    );
};

export default SessionList;
