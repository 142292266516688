import React, { useMemo } from 'react';
import EnhancedTable from '../enhancedTable/EnhancedTable';

const columns = {
    2: [
        { field: 'taskNo', type: 'numeric' },
        { field: 'afterfake', type: 'numeric' },
        { field: 'correct', type: 'numeric' },
        { field: 'difficultyState', type: 'numeric'},
        { field: 'mistakeafter', type: 'numeric' },
        { field: 'time', type: 'numeric' },
    ],
    3: [],
    4: [
        { field: 'taskNo', type: 'numeric' },
        { field: 'currentDifficulty', type: 'numeric' },
        { field: 'currentGameTime', type: 'numeric' },
        { field: 'correct'},
        { field: 'GAME_TYPE' },
        { field: 'reactionTime', type: 'numeric' },
        { field: 'selectedCard', type: 'numeric' },
        { field: 'startingNumber', type: 'numeric' },
        { field: 'successfulMoves', type: 'numeric' }
    ]
};

const fitTasks = function(taskResults) {
    return taskResults.reduce((items, element) => {
        const itemIndex = element.taskNumber - 1;
        const item = items[itemIndex] || (items[itemIndex] = {
            taskNo: element.taskNumber
        });

        item[element.key] = element.value;
        return items;
    }, []);
};

const CogniteSessionResultsTable = (props) => {
    return (
        <div className="ListLayoutFullWidth">
            <EnhancedTable
                {...{
                    title: 'resources.cogniteresults.name',
                    tableName: 'cogniteresults', //For column translation purposes only
                    customData: fitTasks(props.taskResults),
                    columns: columns[props.moduleId],
                    editable: false,
                    customOptions: {
                        pageSize: 20,
                        exportButton: true,
                    }
                }}
            />
        </div>
    );
};

export default CogniteSessionResultsTable;